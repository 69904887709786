header {
    box-shadow: 0px 2px 18px 2px rgba(208,208,208,1);
    -webkit-box-shadow: 0px 2px 18px 2px rgba(208,208,208,1);
    -moz-box-shadow: 0px 2px 18px 2px rgba(208,208,208,1);
}

.header-logo {
    img {
        width: 200px;
        height: auto;
    }
}

.header-item {
    font-weight: bold;
    font-size: 1em;

    &.orange {
        color: #ffb65a;
        &:hover {
            color: #2a1a58;
        }
    }
}


.mobile-menu {
    text-align: center;
    flex-flow: row wrap;

    .header-item {
        flex-basis: 100%;
    }
}