.react-cookie-law-dialog {
  bottom: 0;
  top: unset !important;
}

.react-cookie-law-manage-btn {
  background-color: gray !important;
}

.react-cookie-law-accept-btn {
  background-color: #ffb65a !important;
}

.react-cookie-law-save-btn {
  background-color: lightslategray !important;
}

.react-cookie-law-dialog button {
  span {
    color: black !important;
  }
  margin: 0.5rem 0.8rem !important;
  padding: 0.5rem 1rem !important;
  font-weight: 500 !important;
  border: 0;
  border-radius: 0.5em !important;
  font-size: 0.9em !important;
  text-transform: uppercase;

  box-shadow: 0 2px 15px 2px #d0d0d0;
  -webkit-box-shadow: 0 2px 15px 2px #d0d0d0;
  -moz-box-shadow: 0 2px 15px 2px #d0d0d0;
}
