.showcase {
    img {
        width: 100%;
        height: auto;
    }

    p {
        font-weight: 600;
        font-size: 1.1em;
    }
}

.features {
    background-color: #ffefdb;
    .content {
        .image {
            height: 140px;
            text-align: center;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        p {
            font-weight: 500;
            a {
                color: #ffa538 !important;
            }
        }
    }
}

.devices {
    img {
        width: 100%;
    }
}

.video {
    background-color: #daf3ff;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.prices {
    text-align: center;
    .prices-container {
        flex-flow: row wrap;
        .price-container {
            max-width: 450px;
            flex: 1;
            overflow: hidden;
            box-shadow: 0px 2px 18px 2px rgba(208,208,208,1);
            -webkit-box-shadow: 0px 2px 18px 2px rgba(208,208,208,1);
            -moz-box-shadow: 0px 2px 18px 2px rgba(208,208,208,1);
            &:nth-child(even)
            {
                background-color: #ffefdb;
                position: relative;
            }
        }
        ul {
            display: inline-block;
            padding: 0;
            margin: 0 auto;
            text-align: left;
            list-style: none;
            li {
                font-weight: 500;
                &:not(.empty):before {
                    content: "";
                    margin-right: 0.3em;
                    background: transparent url("../images/checkmark.webp") no-repeat;
                    width: 20px;
                    height: 21px;
                    display: inline-block;
                }
                &.empty {
                    height: 28px;
                }
            }
        }
    }
    .flag {
        right: -48px;
        position: absolute;
        width: 128px;
        height: 64px;
        transform: rotate(45deg);
        background: #ffa538;
        top: -10px;
        box-shadow: 0px 2px 18px 2px #d0d0d0;
        -webkit-box-shadow: 0px 2px 18px 2px #d0d0d0;
        -moz-box-shadow: 0px 2px 18px 2px #d0d0d0;
        span {
            position: absolute;
            top: 30px;
            font-weight: bolder;
            font-size: 1.2rem;
            right: 47px;
        }
    }
}

.contact {
    background-color: #dfffe8;
    font-weight: 500;

    img {
        width: 220px;
    }
}

.modal
{
    button.react-calendar__tile:disabled
    {
        background-color: #ffd7d7;
    }

    .legend {
        width: 350px;
    }

    select {
        width: 350px;
    }

    .btn-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

.btn-orange {
    font-weight: 700 !important;
    background-color: #ffb65a !important;
    border-radius: 0.5em !important;
    font-size: 1.1em !important;
    text-transform: uppercase;
}

.btn-orange:not(.no-shadow) {
    box-shadow: 0 2px 15px 2px #d0d0d0;
    -webkit-box-shadow: 0 2px 15px 2px #d0d0d0;
    -moz-box-shadow: 0 2px 15px 2px #d0d0d0;
}

.header-item div {
    color: #2a1a58 !important;
}

a.header-item {
    text-decoration: none;
}
