@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');

body {
    font-family: 'Montserrat', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bolder !important;
}

@media only screen and (max-width: 575px) {
    body {
        text-align: center;
    }
}

@media only screen and (max-width: 1200px) {
    .price-container {
        flex-basis: 45% !important;
        margin: 0 1em;
        &.py-5 {
            order: 1;
        }
    }
}

@media only screen and (max-width: 900px) {
    .price-container {
        flex-basis: 100% !important;
        &.py-5 {
            order: 0;
        }
    }
}

@media only screen and (max-width: 767px) {
    .showcase {
        text-align: center;

        img {
            max-width: 500px;
        }
    }
}

.checkable-option-container {
    background-color: white;
    border-radius: 1rem;
    border: 2px solid #d0d0d0;
    hr {
        width: 90%;
    }
}

.calendar-container {
    .box {
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid #d0d0d0;
    }
}

.checkable-option {
    cursor: pointer;
}